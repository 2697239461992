import { getToken } from "@/untils/auth";
import { postTbfwCheckStatus, userInfo } from "@/api/major";
import { Message } from 'element-ui'

export async function blankDZ () {
  let token = getToken();
  let hrefUrl =
    "http://etest.sinochemitc.com/ebidding/outer/hysz/loginForHysz.htm?token=" +
    token;
  if (process.env.VUE_APP_BASE_URL === "https://d.sinochemitc.com") {
    hrefUrl =
      "http://e.sinochemitc.com/ebidding/outer/hysz/loginForHysz.htm?token=" +
      token;
  }
  let res = await userInfo();
  if (res.code === '200') {
    let ress = await postTbfwCheckStatus();
    if (ress.code == 200) {
      if (ress.data.tbfwStatus == 1) {
        window.open(hrefUrl);
      } else {
        if (window.innerWidth <= 600) {
          window.open(process.env.VUE_APP_BASE_URL + "/cH5/personal/index");
        } else {
          if (res.data.id) {
            let romptPialog =
              JSON.parse(localStorage.getItem("romptPialogIsShows")) || {};
            romptPialog[res.data.id] = false;

            localStorage.setItem(
              "romptPialogIsShows",
              JSON.stringify(romptPialog)
            );
          }
          window.open(process.env.VUE_APP_BASE_URL + "/cWeb/home/index");
        }
      }
    } else {
      Message.error(ress.message)
    }
  } else {
    if (res.code === '401') {
      if (window.innerWidth <= 600) {
        window.open(process.env.VUE_APP_BASE_URL + "/cH5/login");
      } else {
        window.open(process.env.VUE_APP_BASE_URL + "/cWeb/login");
      }
    } else {
      Message.error(res.message || '查询出错')
    }
  }

}