var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_body"},[_c('div',{staticClass:"top_list"},[_c('ul',_vm._l((_vm.dataList),function(item,index){return _c('li',{key:index},[_c('el-link',{attrs:{"disabled":item.url ? false : true,"underline":false,"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.title))])],1)}),0)]),_vm._m(0)]),(!_vm.$route.path.includes('/assetsDisposal'))?[_c('div',{staticClass:"share"},[_c('ul',_vm._l((_vm.shareDate),function(item,index){return _c('li',{key:index,on:{"mouseenter":function($event){return _vm.ewmBox(index)},"mouseleave":function($event){_vm.ewmFlag = false}}},[_c('img',{attrs:{"src":item.path,"alt":""},on:{"click":function($event){return _vm.iconClick(index)}}})])}),0),(_vm.ewmFlag)?_c('div',{staticClass:"ewm"}):_vm._e()]),_c('div',{staticClass:"share2",style:({
        bottom: _vm.ewmFlag
          ? 'calc(100vw * 250 / 1920)'
          : 'calc(100vw * 100 / 1920)',
      })},[(_vm.lingtan)?_c('div',{staticClass:"lingtan"}):_c('div',{staticClass:"ewm"}),_c('div',{staticClass:"zi"},[_c('div',{staticClass:"one"},[_vm._v(_vm._s(_vm.lingtan ? "化云零碳产品" : "化云数智产品"))]),(!_vm.lingtan)?_c('div',{staticClass:"two"},[_c('div',{staticClass:"tow_1"},[_vm._v("免费试用")]),_c('div',[_vm._v("通道已开启")])]):_vm._e(),_c('div',{staticClass:"san",style:({ 'margin-top': _vm.lingtan ? 'calc(100vw * 20 / 1920)' : '' })},[_vm._v(" （扫码咨询） ")])])])]:_vm._e(),(_vm.$route.path.includes('/assetsDisposal'))?[_c('div',{staticClass:"share2",style:({
        bottom: _vm.ewmFlag
          ? 'calc(100vw * 250 / 1920)'
          : 'calc(100vw * 100 / 1920)',
      })},[_c('div',{staticClass:"ewm2"}),_c('div',{staticClass:"zi"},[_c('div',{staticClass:"two"},[_vm._v(_vm._s("联系人"))]),_c('div',{staticClass:"san",staticStyle:{"font-size":"calc(100vw * 18 / 1920)"}},[_vm._v(" 王经理：18911319617 ")]),_c('div',{staticClass:"san",staticStyle:{"font-size":"calc(100vw * 18 / 1920)"}},[_vm._v(" 吴经理：18110123604 ")])])])]:_vm._e(),_c('div',{staticClass:"footer_body_h5"},[_c('div',{staticClass:"link"},[_vm._v("友情链接")]),_c('ul',{staticClass:"bom_link"},_vm._l((_vm.dataList),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(index == '0' ? false : true),expression:"index == '0' ? false : true"}],key:index},[_c('el-link',{attrs:{"underline":false,"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.title))])],1)}),0),_c('div',{staticClass:"jx"}),_vm._m(1)])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_list"},[_vm._v(" 版权所有 中化商务有限公司 "),_c('a',{attrs:{"target":"_blank","href":"https://beian.miit.gov.cn/"}},[_vm._v("京ICP备18039018号-1")]),_c('br'),_vm._v(" 京公安网安备 11040130081号 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_list"},[_vm._v(" 版权所有 中化商务有限公司 "),_c('a',{attrs:{"target":"_blank","href":"https://beian.miit.gov.cn/"}},[_vm._v("京ICP备18039018号-1")]),_c('br'),_vm._v(" 京公安网安备 11040130081号 ")])
}]

export { render, staticRenderFns }