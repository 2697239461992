<template>
  <div class="Layout">
    <Header @litsFlag="litsFlagFun"></Header>
    <Nav v-show="isPc"></Nav>
    <NavH5 ref="NavRef" v-show="!isPc"></NavH5>
    <div class="main">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './components/Header'
import Nav from './components/Nav'
import NavH5 from './components/NavH5'
import Footer from './components/Footer'
export default {
  components: {
    Header,
    Nav,
    NavH5,
    Footer
  },
  data() {
    return {
      windowWidth: 0
    }
  },
  computed: {
    isPc() {
      return this.windowWidth > 600
    }
  },
  created() {
    // this.$store.dispatch('initUserData')
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    // h5侧边栏
    litsFlagFun(e) {
      this.$refs['NavRef'].litsFlag = e
    }
  }
}
</script>
<style lang="less" scoped>
.Layout{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .main{
    width: 100%;
    flex: 1;
  }
}
</style>