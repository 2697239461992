import { get, post } from '../untils/newRequest'
// announcementList 竞拍预告
// biddingList 竞拍中
// endList 竞拍结束
export function idleAssets_list (parms) {
  return get(`api/third/idleAssets/list`, parms)
}

/**
2、
入参
size 每页数量
current 当前页数
subjectType 标的类型
district 标的所在地
transactionMode 组织方式
projectStatus 项目状态
keyword 关键词

返回参数
subjectId 标的物ID
subjectPicUrl 标的物图片
subjectName 标的名称
sellerName 转让方
listingPrice 挂牌价格
listingEndDate 挂牌结束日期
district 所属地区
transactionMode 组织方式
subjectType 标的类型
projectStatus 项目状态
 */

export function idleAssets_listAll (parms) {
  return get(`api/third/idleAssets/listAll`, parms)
}

// catalog 标的信息
// otherDisclosure 重要信息披露
// tradeNotice 交易须知
// riskWarningNotice 风险提示（也在交易须知展示）
// outId 交易系统id，调完整公告用，完整公告在https://www.dscq.com/fullDetailInfo.shtml?assetsId={outId}
// attachmentList：{"category":"类型", "fileName":"文件名称"} 相关附件
export function mhDetail_assetsId (parms) {
  return get(`xfwz/api/projectassets/mhDetail`, parms)
}

export function assets_operation_type (parms) {
  return get(`xfwz/api/prepare-project/assets/assets-operation-type`, parms)
}


export function myAssets (parms) {
  return get('api/third/idleAssets/myAssets', parms)
}


// 2、废旧物资-详情增加一个接口
export function idleAssetsDetail (parms) {
  return get(`api/third/idleAssets/detail/${parms.subjectId}`)
}

export function queryBannerList (parms) {
  return get(`portal/xfwzBanner/index/queryBannerList`, parms)
}


// 资产包 详情-项目目录
export function detail_package (parms) {
  return get(`api/third/idleAssets/detail/package`, parms)
}

export function idleAssetsAttachment (parms) {
  return post(`api/third/idleAssets/attachment`, parms, { responseType: 'blob' })
}

export function idleAssetsAttachmentUrl (parms) {
  return post(`api/third/idleAssets/attachment-url`, parms)
}

// 闲废 最新挂牌
export function idleAssets_latest (parms) {
  return get(`api/third/idleAssets/latest`, parms)
}


// ?current=1&size=10&keyword=
// 闲废 顶部搜索
export function idleAssets_find (parms) {
  return get(`api/third/idleAssets/find`, parms)
}


// 首页-招商预告  更多-招商预告
export function mhPage (parms) {
  return get(`xfwz/api/attract-investment/assets/mhPage`, parms)
}
// current=1
// size=10 
// keyword=关键词
// subjectTypeCodeOne 标的类型编码选一级时，用这个字段
// subjectTypeCodeTwo 标的类型编码选二级时，用这个字段
// subjectTypeCodeThree 标的类型编码选三级时，用这个字段
// district 所属地区

// id id
// subjectPicUrl 资产图片url
// subjectName 资产名称
// listingTime 预估挂牌时间
// sellerName 转让方名称
// estimatedPrice 预估价格

// 3、?id={id}
// 招商预告详情
export function mhDetail (parms) {
  return get(`xfwz/api/attract-investment/assets/mhDetail`, parms)
}

// id id
// subjectPicUrlList 资产图片链接数组
// videoUrlList 视频链接，里面不是对象了，字符串数组
// vrUrlList 360全景链接数组
// viewNum 围观次数（目前没有值）
// subjectName 资产名称
// organizationParty 组织方名称
// sellerName 转让方名称
// subjectType 标的类型
// district 所属地区
// listingTime 预估挂牌时间
// estimatedPrice 预估价格
// isSurveyShow 是否显示踏勘 1是 0否
// content 预告信息


// 查询项目咨询联系人
export function viewContact (parms) {
  return get(`api/third/attractInvestment/viewContact/${parms.id}`,)
}


// 提交踏勘申请
// id 投资预告id
// applyName 企业名称/个人姓名
// applyType 类型: 企业传1 个人传2
// startTime 期望踏勘-起始时间段 日期yyyy-MM-dd
// endTime 期望踏勘-终止时间段 日期yyyy-MM-dd
export function attractInvestment_apply (parms) {
  return post(`api/third/attractInvestment/apply`, parms)
}
export function shareUrlSign (parms) {
  return post(`api/third/wx/shareUrlSign`, parms)
}

