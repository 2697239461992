<template>
  <div class="footer">
    <div class="footer_body">
      <div class="top_list">
        <ul>
          <li v-for="(item, index) in dataList" :key="index">
            <el-link
              :disabled="item.url ? false : true"
              :underline="false"
              :href="item.url"
              target="_blank"
              >{{ item.title }}</el-link
            >
          </li>
        </ul>
      </div>
      <div class="foot_list">
        版权所有 中化商务有限公司
        <a target="_blank" href="https://beian.miit.gov.cn/"
          >京ICP备18039018号-1</a
        >
        <br />
        京公安网安备 11040130081号
      </div>
    </div>
    <!-- 右下角分享 -->
    <template v-if="!$route.path.includes('/assetsDisposal')">
      <div class="share">
        <ul>
          <li
            v-for="(item, index) in shareDate"
            :key="index"
            @mouseenter="ewmBox(index)"
            @mouseleave="ewmFlag = false"
          >
            <img :src="item.path" alt="" @click="iconClick(index)" />
          </li>
        </ul>
        <div class="ewm" v-if="ewmFlag"></div>
      </div>

      <!-- <div
        class="share2"
        :style="{
          bottom: ewmFlag
            ? 'calc(100vw * 250 / 1920)'
            : 'calc(100vw * 100 / 1920)',
        }"
      >
        <div class="ewm"></div>
        <div class="zi">
          <div class="one">化云数智产品</div>
          <div class="two">
            <div class="tow_1">免费试用</div>
            <div>通道已开启</div>
          </div>
          <div class="san">（扫码咨询）</div>
        </div>
      </div> -->
      <div
        class="share2"
        :style="{
          bottom: ewmFlag
            ? 'calc(100vw * 250 / 1920)'
            : 'calc(100vw * 100 / 1920)',
        }"
      >
        <div v-if="lingtan" class="lingtan"></div>
        <div v-else class="ewm"></div>
        <div class="zi">
          <div class="one">{{ lingtan ? "化云零碳产品" : "化云数智产品" }}</div>
          <div class="two" v-if="!lingtan">
            <div class="tow_1">免费试用</div>
            <div>通道已开启</div>
          </div>
          <div
            :style="{ 'margin-top': lingtan ? 'calc(100vw * 20 / 1920)' : '' }"
            class="san"
          >
            （扫码咨询）
          </div>
        </div>
      </div>
    </template>

    <template v-if="$route.path.includes('/assetsDisposal')">
      <div
        class="share2"
        :style="{
          bottom: ewmFlag
            ? 'calc(100vw * 250 / 1920)'
            : 'calc(100vw * 100 / 1920)',
        }"
      >
        <div class="ewm2"></div>
        <div class="zi">
          <div class="two">{{ "联系人" }}</div>
          <div class="san" style="font-size: calc(100vw * 18 / 1920)">
            王经理：18911319617
          </div>
          <div class="san" style="font-size: calc(100vw * 18 / 1920)">
            吴经理：18110123604
          </div>
        </div>
      </div>
    </template>

    <div class="footer_body_h5">
      <div class="link">友情链接</div>
      <ul class="bom_link">
        <li
          v-for="(item, index) in dataList"
          :key="index"
          v-show="index == '0' ? false : true"
        >
          <el-link :underline="false" :href="item.url" target="_blank">{{
            item.title
          }}</el-link>
        </li>
      </ul>

      <div class="jx"></div>
      <div class="foot_list">
        版权所有 中化商务有限公司
        <a target="_blank" href="https://beian.miit.gov.cn/"
          >京ICP备18039018号-1</a
        >
        <br />
        京公安网安备 11040130081号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lingtan: false,
      ewmFlag: false,
      dataList: [
        { title: "友情链接" },
        {
          title: "中化商务电子招投标平台",
          url: "http://e.sinochemitc.com/cms/index.htm",
        },
        {
          title: "中国中化电商平台",
          url: "https://www.esinochem.com/index",
        },
        {
          title: "中国中化产融开放平台",
          url: "https://ifs.sinochemdigits.com/finance/index",
        },
        {
          title: "中国招标投标协会",
          url: "http://www.ctba.org.cn/",
        },
        {
          title: "点点碳中和",
          url: "https://cns.sceex.com.cn/",
        },
      ],
      shareDate: [
        { path: require("../../../assets/img/pc_img/20.png") },
        { path: require("../../../assets/img/pc_img/21.png") },
        { path: require("../../../assets/img/pc_img/22.png") },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.lingtan = this.$route.path === "/lingtan";
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    iconClick(index) {
      if (index == 0) {
        document.body.scrollIntoView({
          behavior: "smooth", //behavior可以定义上或下,smooth是元素平滑的滚动到与用户视图窗口顶部齐平的地方，底部齐平即end
          block: "start", //block是动画
        });
      } else if (index == 1) {
        window.open(
          "https://1888533.s4.udesk.cn/im_client/?web_plugin_id=49356&agent_id=55403&group_id=43300",
          "_blank"
        );
      } else {
        return;
      }
    },
    ewmBox(index) {
      if (index == "2") {
        this.ewmFlag = true;
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: calc(100vw * 312 / 1920);
  background: #121831;
  margin: auto;
  padding-top: calc(100vw * 80 / 1920);
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  z-index: 9999;
  .footer_body_h5 {
    display: none;
  }
  .share {
    width: calc(100vw * 67 / 1920);
    height: calc(100vw * 191 / 1920);
    position: fixed;
    bottom: calc(100vw * 100 / 1920);
    right: calc(100vw * 10 / 1920);
    z-index: 999999;
    ul > li {
      width: calc(100vw * 67 / 1920);
      height: calc(100vw * 67 / 1920);
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ewm {
      width: 12rem;
      height: 12rem;
      background: url("../../../assets/img/pc_img/43.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: -12rem;
      top: 3rem;
    }
  }
  .share2 {
    width: calc(100vw * 200 / 1920);
    height: calc(100vw * 438 / 1920);
    position: fixed;
    // bottom: calc(100vw * 100 / 1920);
    right: calc(100vw * 100 / 1920);
    z-index: 999999;
    background-image: url("../../../assets/fu.png");
    background-repeat: no-repeat;
    background-size: contain;

    .zi {
      margin-top: calc(100vw * 9 / 1920);
      text-align: center;

      .one {
        font-family: AlibabaPuHuiTi_2_65_Medium;
        font-weight: 700;
        font-size: calc(100vw * 28 / 1920);
        color: #ffffff;
        line-height: calc(100vw * 35 / 1920);
        text-align: center;
        font-style: normal;
        text-transform: none;
      }

      .two {
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 400;
        font-size: calc(100vw * 20 / 1920);
        color: #ffffff;
        line-height: calc(100vw * 35 / 1920);

        .tow_1 {
          width: calc(100vw * 84 / 1920);
          height: calc(100vw * 26 / 1920);
          background: #ff5900;
          border-radius: calc(100vw * 5 / 1920);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff86c;
        }
      }

      .san {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: calc(100vw * 20 / 1920);
        color: #ffffff;
      }
    }

    .ewm {
      width: calc(100vw * 200 / 1920);
      height: calc(100vw * 200 / 1920);
      background: url("../../../assets/ewm.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: calc(100vw * 126 / 1920);
    }

    .ewm2 {
      width: calc(100vw * 200 / 1920);
      height: calc(100vw * 200 / 1920);
      background: url("../../../assets/ewm2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: calc(100vw * 126 / 1920);
      border-radius: calc(100vw * 14 / 1920);
    }
    .lingtan {
      border: 1px solid #409eff;
      width: calc(100vw * 200 / 1920);
      border-radius: 5px;
      height: calc(100vw * 200 / 1920);
      overflow: hidden;
      background: url("../../../assets/lingtan/wx.png");
      background-size: 240% 240%;
      background-position: 53% 73%;
      background-repeat: no-repeat;
      margin-top: calc(100vw * 126 / 1920);
      box-sizing: border-box;
    }
  }
  .footer_body {
    padding: 0rem 1rem;
    box-sizing: border-box;
    height: calc(100vw * 100 / 1920);
    font-size: calc(100vw * 15 / 1920);
    font-family: "AlibabaPuHuiTi_2_65_Medium";
    color: #848d93;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top_list {
      width: 100%;
      height: calc(100vw * 50 / 1920);
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          float: left;
          margin-right: 2rem;
          /deep/ .el-link--inner {
            font-size: calc(100vw * 15 / 1920);
            font-family: "AlibabaPuHuiTi_2_65_Medium";
            color: #848d93;
          }
        }
      }
    }
    .foot_list {
      width: 100%;
      height: calc(100vw * 50 / 1920);
      text-align: center;

      a {
        color: #848d93;
        &:hover {
          color: #f5f5f5;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .footer {
    .share2 {
      display: none !important;
    }
    height: 15.2rem;
    padding: 3.3rem 0.7rem 0rem 1rem;
    z-index: 99;
    .footer_body {
      display: none !important;
    }
    .footer_body_h5 {
      width: 100%;
      height: 100%;
      display: block;
      .link {
        height: 1.05rem;
        font-size: 0.75rem;
        font-family: "AlibabaPuHuiTi_2_55_Regular";
        color: #848d93;
        line-height: 1.05rem;
        margin-bottom: 0.75rem;
      }
      .bom_link {
        width: 100%;
        // height: 3rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        ul li a span {
          font-size: 0.75rem;
          font-family: "AlibabaPuHuiTi_2_55_Regular";
          color: #848d93;
          line-height: 1.05rem;
          margin-bottom: 0.75rem;
        }
      }
      .jx {
        width: 96%;
        height: 0.05rem;
        background: #848d93;
        margin: auto;
        margin: 1.3rem 0rem 1.5rem 0rem;
      }
      .foot_list {
        text-align: center;
        font-size: 0.75rem;
        font-family: "AlibabaPuHuiTi_2_55_Regular";
        color: #848d93;
        line-height: 1.05rem;
        margin-bottom: 0.75rem;
        a {
          color: #848d93;
          &:hover {
            color: #f5f5f5;
          }
        }
      }
    }
    .share {
      width: calc(100vw * 300 / 1920);
      height: calc(100vw * 900 / 1920);
      position: fixed;
      bottom: calc(100vw * 100 / 1920);
      right: calc(100vw * 10 / 1920);
      z-index: 999999;
      ul > li {
        width: calc(100vw * 300 / 1920);
        height: calc(100vw * 300 / 1920);
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ewm {
        width: 12rem;
        height: 12rem;
        background: url("../../../assets/img/pc_img/43.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: -12rem;
        top: -1rem;
      }
    }
  }
}
</style>
