import {get, post} from '../untils/newRequest'
// 搜索功能
export function queryAllInfoList(title) {
  return get(`/portal/index/queryAllInfoList/${title}`)
}

//获取广告位
export function getAdvertList(params) {
  return get('/api/portal/advert/queryAdvertList', params)
}

export function hy_bulletin_find(params) {
  // current=1&size=10&keyword=关键词
  return get('api/management/bidding/hy-bulletin-find', params)
}

