import { get, post } from '../untils/newRequest'
// 销售服务详情
export function saleServiceDetail (params) {
	return get('/api/third/chargeItem/saleServiceDetail/' + params.id)
}

export function userInfo (params) {
	return get('/api/third/singleSign/userInfo', params)
}

// 创建订单
export function saveOrder (params) {
	return post('/api/third/chargeOrder/saveOrder', params)
}

// 订单状态
export function getPayStatus (params) {
	return get('/api/third/chargeHistory/payStatus/' + params.orderCode)
}


// 退出登录
export function logout (params) {
	return post('/api/user/auth/logout', params)
}


// 查询是否为首个账号
export function postTbfwCheckStatus (params) {
	return get('/api/user/tbfw/checkStatus', params)
}

export function getaccount (params) {
	return get('/api/user/auth/account', params)
}

// 1、化云招采公告列表
/*
current 当前页数
size 每页数量
bulletinType 必传 1招标/预审/变更 2评标结果/中标结果 3非招标采购公告
bidType 10货物 20工程 30服务 全部时不传值
keyword 关键词
startTime yyyy-MM-dd 发布日期-开始
endTime yyyy-MM-dd 发布日期-结束
*/
export function hyBulletinList (params) {
	return get('api/management/bidding/hy-bulletin-list', params)
}

// 化云招采公告正文
export function hyBulletinDetail (params) {
	return get(`api/management/bidding/hy-bulletin-detail/${params.id}`)
}

// 招标表单提交
export function saveInvite (params) {
	return post(`api/management/bidding/save-invite`, params)
}

// 门户获取最新证书链接
export function certificateUrl () {
	return get('api/third/carbon/certificateUrl')
}