<template>
  <div
    class="Nav"
    v-if="
      !(
        $route.query.noNav &&
        $route.path.includes('/assetsDisposal') &&
        !$cookie.get('c-Authorization')
      )
    "
  >
    <template>
      <div v-for="item in navList" :key="item.id">
        <el-popover
          v-if="item.children"
          placement="bottom"
          trigger="hover"
          v-model="item.visible"
        >
          <div class="pull_down">
            <div
              v-for="child in item.children"
              :key="child.title"
              class="pull_left"
            >
              <div class="pull_title">{{ child.title }}</div>
              <ul>
                <li
                  v-for="data in child.list"
                  @click="navChildChange(item, data)"
                  :key="data.name"
                >
                  {{ data.name }}
                </li>
              </ul>
            </div>
          </div>
          <div
            slot="reference"
            @click="navChange(item)"
            :class="['title', id == item.id ? 'on' : '']"
          >
            {{ item.title }}
          </div>
        </el-popover>
        <div
          v-else
          :class="['title', $route.path?.indexOf(item.Url) !== -1 ? 'on' : '']"
          @click="navChange(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { blankDZ } from "@/untils/newFun";
export default {
  data() {
    return {
      id: 0,
      userId: 0,
      visible: false,
      navList: [
        {
          id: 0,
          title: "首页",
          visible: false,
          Url: "/home/index",
        },
        {
          id: 1,
          title: "化云招采公告",
          visible: false,
          Url: "/zcnotice",
        },
        {
          id: 2,
          title: "化云平台服务",
          visible: false,
          Url: "/home/index",
          query: { queryData: 0 },
          children: [
            {
              title: "招标人服务",
              list: [
                {
                  name: "招采过程管控",
                  Url: "/control/index",
                },
                {
                  name: "化云合同管控",
                  Url: "/contract/index",
                },
                {
                  name: "化云风控",
                  Url: "/fengkongHome/index",
                },
                {
                  name: "化云寻价",
                  Url: "/inquiry/index",
                },
                {
                  name: "化云市场调研",
                  Url: "/bannerservices/index?name=true",
                },
              ],
            },
            {
              title: "投标人服务",
              list: [
                {
                  name: "我要投标",
                },
                {
                  name: "化云标讯",
                  Url: "/bannerServices/index",
                },
                {
                  name: "化易融",
                  Url: "/finance",
                },
                {
                  name: "化云潜客",
                  Url: "/potential",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "解决方案",
          Url: "/home/index",
          query: { queryData: 1 },
          visible: false,
          children: [
            {
              title: "化易采",
              list: [
                {
                  name: "采购风控",
                  Url: "/hycSolve",
                  query: { queryData: 2 },
                },
                {
                  name: "电子招投标",
                  query: { queryData: 1 },
                  Url: "/hycSolve",
                },
                {
                  name: "离线评审",
                  Url: "/hycSolve",
                  query: { queryData: 0 },
                },
                {
                  name: "采购管理",
                  query: { queryData: 3 },
                  Url: "/hycSolve",
                },
              ],
            },
            {
              title: "化易建",
              list: [
                {
                  name: "工程管理能力评估",
                  Url: "/enterprise",
                },
              ],
            },
            {
              title: "行业云",
              list: [
                {
                  name: "化工行业云",
                  Url: "/hghyy",
                },
              ],
            },
          ],
        },
        {
          id: 9,
          title: "化云甄选",
          visible: false,
          Url: "/preferred/index",
        },
        {
          id: 4,
          title: "化云闲余",
          visible: false,
          Url: "/assetsDisposal",
        },
        {
          id: 5,
          title: "化云零碳",
          visible: false,
          Url: "/lingtan",
        },
        {
          id: 6,
          title: "更多业务",
          visible: false,
          children: [
            {
              title: "供应链服务",
              list: [
                {
                  name: "查看详情",
                  Url: "/supply",
                },
              ],
            },
            {
              title: "工程服务",
              list: [
                {
                  name: "工程管理能力评估",
                  Url: "/enterprise",
                },
              ],
            },
            {
              title: "产业咨询",
              list: [
                {
                  name: "查看详情",
                  Url: "/consulting",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          title: "政策法规",
          visible: false,
          Url: "/policy",
        },
        {
          id: 10,
          title: "培训课程",
          visible: false,
          Url: "/train",
        },
        {
          id: 8,
          title: "关于我们",
          visible: false,
          Url: "/regardme",
        },
      ],
    };
  },
  watch: {
    userData() {
      this.userId = this.$store.getters.userData.id;
    },
    $route() {
      this.litsFlagGoback = false;
    },
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
  },
  created() {
    this.id = sessionStorage.getItem("navIndex");
  },
  methods: {
    blankDZ,

    navChange(item) {
      this.id = -1;
      sessionStorage.removeItem("navIndex");
      if (item.Url) {
        if (window.innerWidth > 600) {
          sessionStorage.setItem("scrollIntoView", JSON.stringify(item.query));
          this.$router.push({
            path: item.Url,
            query: item.query,
          });
        } else {
          this.$router.push({
            path: item.Url,
            query: item.query,
          });
        }
        if (item.query?.queryData || item.query?.queryData === 0) {
          this.$nextTick(() => {
            document.getElementById(item.query.queryData).scrollIntoView({
              behavior: "smooth", // 定义动画过渡效果
              block: "start", // 定义垂直方向的对齐
              inline: "nearest", // 定义水平方向的对齐
            });
          });
          sessionStorage.removeItem("scrollIntoView");
        }
      }
      if (item.title == "我要投标") {
        blankDZ();
      }
    },
    navChildChange(item, data) {
      this.id = item.id;
      sessionStorage.setItem("navIndex", item.id);
      if (data.Url) {
        if (window.innerWidth > 600) {
          this.$router.push({
            path: data.Url,
            query: data.query,
          });
        } else {
          this.$router.push({
            path: data.Url,
            query: data.query,
          });
        }
      }
      if (data.name == "我要投标") {
        sessionStorage.removeItem("navIndex");
        this.id = -1;
        blankDZ();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.Nav {
  background: white;
  font-family: "AlibabaPuHuiTi_2_65_Medium";
  padding: calc(100vw * 12 / 1920) calc(100vw * 120 / 1920) 0
    calc(100vw * 120 / 1920);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  .title {
    cursor: pointer;
    font-size: 1rem;
    padding-right: calc(100vw * 10 / 1920);
    padding-left: calc(100vw * 10 / 1920);
    text-align: center;
    padding-bottom: calc(100vw * 12 / 1920);
    border-bottom: 3px solid transparent;
  }
  .on {
    border-bottom: 3px solid #3d7fff;
  }
}
.el-popover .pull_down {
  font-family: "AlibabaPuHuiTi_2_65_Medium";
  // min-height: calc(100vw * 324 / 1920);
  background: #ffffff;
  border-radius: calc(100vw * 6 / 1920) calc(100vw * 6 / 1920)
    calc(100vw * 6 / 1920) calc(100vw * 6 / 1920);
  padding: calc(100vw * 20 / 1920) 0 calc(100vw * 20 / 1920)
    calc(100vw * 35 / 1920);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  .pull_left {
    // width: calc(100vw * 160 / 1920);
    margin-right: calc(100vw * 35 / 1920);
    height: 100%;
    .pull_title {
      font-family: "AlibabaPuHuiTi_2_65_Medium";
      width: calc(100vw * 130 / 1920);
      height: calc(100vw * 27 / 1920);
      font-size: calc(100vw * 20 / 1920);
      color: #203152;
      line-height: calc(100vw * 15 / 1920);
      border-bottom: calc(100vw * 2 / 1920) solid rgba(0, 0, 0, 0.1);
      margin-bottom: calc(100vw * 20 / 1920);
    }
    ul > li {
      cursor: pointer;
      width: 100%;
      font-size: calc(100vw * 16 / 1920);
      font-family: "AlibabaPuHuiTi_2_45_Light";
      color: #203152;
      line-height: calc(100vw * 40 / 1920);
    }
    ul > li:hover {
      color: #3d7fff;
    }
  }
}
.Nav > div:hover {
  color: #3d7fff;
}
</style>
