
import Layout from '@/components/Layout'
let fengkong = () => import('@/views/fengKong/index.vue')
const fengkongHome = () => import('@/views/fengKongHome/index.vue')
if (window.innerWidth <= 600) {
  fengkong = () => import('@/views/fengKong/index_h5.vue')
}
const routes = [
  {
    name: 'fengkong',
    component: Layout,
    path: '/fengkong',
    redirect: '/fengkong/index',
    meta: {
      title: '风控'
    },
    showedChild: [],
    children: [
      {
        path: '/fengkong/index',
        name: 'fengkongIndex',
        component: fengkong,
        meta: {
          title: ''
        }
      }
    ]
  },
  {
    name: 'fengkongHome',
    component: Layout,
    path: '/fengkongHome',
    redirect: '/fengkongHome/index',
    meta: {
      title: '风控'
    },
    showedChild: [],
    children: [
      {
        path: '/fengkongHome/index',
        name: 'fengkongHomeIndex',
        component: fengkongHome,
        meta: {
          title: ''
        }
      }
    ]
  }
]

export default routes