var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_home"},[(_vm.litsFlag)?_c('div',{staticClass:"h5_nav_list"},[(_vm.litsFlag)?_c('div',{class:{ mc_box: true, mc_box_2: _vm.he === true }},[_c('div',{staticClass:"button_box"},[(!_vm.userData.username)?_c('div',{staticClass:"button",on:{"click":_vm.login}},[_vm._v(" 登录 ")]):_vm._e(),(!_vm.userData.username)?_c('div',{staticClass:"else_button",on:{"click":_vm.login_Else}},[_vm._v(" 注册 ")]):_vm._e(),(_vm.userData.username)?_c('div',{staticClass:"icon",staticStyle:{"margin-left":"30px","margin-right":"20px","width":"1.5rem"}},[_c('img',{attrs:{"width":"100%","src":require("../../../assets/img/pc_img/hearderIcon.png"),"alt":""}})]):_vm._e(),(_vm.userData.username)?_c('div',{staticStyle:{"flex":"1","font-size":"calc(100vw * 50 / 1920)","font-family":"'AlibabaPuHuiTi_2_65_Medium'","color":"#203152"}},[_vm._v(" 欢迎 "+_vm._s(_vm.userData.username)+" ")]):_vm._e(),(_vm.userData.username)?_c('div',{staticStyle:{"margin-right":"20px","padding":"calc(100vw * 10 / 1920)","box-sizing":"bodder-box","height":"20px","border-radius":"15px","border":"1px solid #3d7fff","font-size":"calc(100vw * 50 / 1920)","font-family":"'AlibabaPuHuiTi_2_85_Bold'","color":"#3d7fff","line-height":"20px"},on:{"click":_vm.loginWb}},[_vm._v(" 进入工作台 ")]):_vm._e()]),(
          !(
            _vm.$route.query.noNav &&
            _vm.$route.path.includes('/assetsDisposal') &&
            !_vm.userData.username
          )
        )?_c('div',{staticClass:"list-box"},[_c('div',{staticClass:"line-box"},_vm._l((22),function(i){return _c('div',{key:i,staticClass:"line"})}),0),_vm._l((_vm.navList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"toneFlag"},[(!item.children)?_c('div',{staticClass:"title",on:{"click":function($event){return _vm.clickTab(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('div',{staticClass:"childFlag"},[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.clickTab(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"childFlag_box"},_vm._l((item.children),function(child,j){return _c('div',{key:j,staticClass:"twoFlag"},[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.clickTab(child)}}},[_vm._v(" "+_vm._s(child.title)+" ")]),_vm._l((child.list),function(childItem,k){return _c('div',{key:k,staticClass:"treFlag"},[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.clickTab(childItem)}}},[_vm._v(" "+_vm._s(childItem.name)+" ")])])})],2)}),0)])])])})],2):_vm._e()]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }