<template>
  <div class="page_home">
    <div class="h5_nav_list" v-if="litsFlag">
      <div :class="{ mc_box: true, mc_box_2: he === true }" v-if="litsFlag">
        <div class="button_box">
          <div class="button" @click="login" v-if="!userData.username">
            登录
          </div>
          <div
            class="else_button"
            @click="login_Else"
            v-if="!userData.username"
          >
            注册
          </div>
          <div
            class="icon"
            style="margin-left: 30px; margin-right: 20px; width: 1.5rem"
            v-if="userData.username"
          >
            <img
              width="100%"
              src="../../../assets/img/pc_img/hearderIcon.png"
              alt=""
            />
          </div>
          <div
            v-if="userData.username"
            style="
              flex: 1;
              font-size: calc(100vw * 50 / 1920);
              font-family: 'AlibabaPuHuiTi_2_65_Medium';
              color: #203152;
            "
          >
            欢迎 {{ userData.username }}
          </div>
          <div
            v-if="userData.username"
            style="
              margin-right: 20px;
              padding: calc(100vw * 10 / 1920);
              box-sizing: bodder-box;
              height: 20px;
              border-radius: 15px;
              border: 1px solid #3d7fff;
              font-size: calc(100vw * 50 / 1920);
              font-family: 'AlibabaPuHuiTi_2_85_Bold';
              color: #3d7fff;
              line-height: 20px;
            "
            @click="loginWb"
          >
            进入工作台
          </div>
        </div>
        <div
          class="list-box"
          v-if="
            !(
              $route.query.noNav &&
              $route.path.includes('/assetsDisposal') &&
              !userData.username
            )
          "
        >
          <div class="line-box">
            <div class="line" v-for="i in 22" :key="i"></div>
          </div>
          <div v-for="(item, index) in navList" :key="index">
            <div class="toneFlag">
              <div class="title" v-if="!item.children" @click="clickTab(item)">
                {{ item.title }}
              </div>
              <div v-else class="childFlag">
                <div class="title" @click="clickTab(item)">
                  {{ item.title }}
                </div>
                <div class="childFlag_box">
                  <div
                    class="twoFlag"
                    v-for="(child, j) in item.children"
                    :key="j"
                  >
                    <div class="title" @click="clickTab(child)">
                      {{ child.title }}
                    </div>
                    <div
                      class="treFlag"
                      v-for="(childItem, k) in child.list"
                      :key="k"
                    >
                      <div class="title" @click="clickTab(childItem)">
                        {{ childItem.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blankDZ } from "@/untils/newFun";

export default {
  data() {
    return {
      navList: [
        {
          id: 0,
          title: "首页",
          visible: false,
          Url: "/home/index",
        },
        {
          id: 1,
          title: "化云招采公告",
          visible: false,
          Url: "/zcnotice",
        },
        {
          id: 2,
          title: "化云平台服务",
          visible: false,
          Url: "/home/index",
          query: { queryData: 0 },
          children: [
            {
              title: "招标人服务",
              list: [
                {
                  name: "招采过程管控",
                  Url: "/control/index",
                },
                {
                  name: "化云合同管控",
                  Url: "/contract/index",
                },
                {
                  name: "化云风控",
                  Url: "/fengkongHome/index",
                },
                {
                  name: "化云寻价",
                  Url: "/inquiry/index",
                },
                {
                  name: "化云市场调研",
                  Url: "/bannerServices/index?name=true",
                },
              ],
            },
            {
              title: "投标人服务",
              list: [
                {
                  name: "我要投标",
                },
                {
                  name: "化云标讯",
                  Url: "/bannerServices/index",
                },
                {
                  name: "化易融",
                  Url: "/finance",
                },
                {
                  name: "化云潜客",
                  Url: "/potential",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "解决方案",
          Url: "/home/index",
          query: { queryData: 1 },
          visible: false,
          children: [
            {
              title: "化易采",
              list: [
                {
                  name: "采购风控",
                  Url: "/hycSolve",
                  query: { queryData: 2 },
                },
                {
                  name: "电子招投标",
                  query: { queryData: 1 },
                  Url: "/hycSolve",
                },
                {
                  name: "离线评审",
                  Url: "/hycSolve",
                  query: { queryData: 0 },
                },
                {
                  name: "采购管理",
                  query: { queryData: 3 },
                  Url: "/hycSolve",
                },
              ],
            },
            {
              title: "化易建",
              list: [
                {
                  name: "工程管理能力评估",
                  Url: "/enterprise",
                },
              ],
            },
            {
              title: "行业云",
              list: [
                {
                  name: "化工行业云",
                  Url: "/hghyy",
                },
              ],
            },
          ],
        },
        {
          id: 9,
          title: " 化云甄选 ",
          visible: false,
          Url: "/preferred/index",
        },
        {
          id: 4,
          title: "化云闲余",
          visible: false,
          Url: "/assetsDisposal",
        },
        {
          id: 5,
          title: " 化云零碳 ",
          visible: false,
          Url: "/lingtan",
        },
        {
          id: 6,
          title: " 更多业务 ",
          visible: false,
          children: [
            {
              title: "供应链服务",
              list: [
                {
                  name: "查看详情",
                  Url: "/supply",
                },
              ],
            },
            {
              title: "工程服务",
              list: [
                {
                  name: "工程管理能力评估",
                  Url: "/enterprise",
                },
              ],
            },
            {
              title: "产业咨询",
              list: [
                {
                  name: "查看详情",
                  Url: "/consulting",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          title: " 政策法规 ",
          visible: false,
          Url: "/policy",
        },
        {
          id: 10,
          title: " 培训课程 ",
          visible: false,
          Url: "/train",
        },
        {
          id: 8,
          title: " 关于我们 ",
          visible: false,
          Url: "/regardme",
        },
      ],
      userId: 0,
      pullFlag: false,
      currentIndex: sessionStorage.getItem("changeIndex") || 0,
      activeName: "9",
      litsFlag: false,
      username: "",
      he: false,
      timeOut: null,
    };
  },
  created() {
    this.getUserInfo();
  },
  watch: {
    $route: {
      handler: function () {
        this.$nextTick(() => {
          let name = this.$route.name;
          this.he = false;
          if (name == "Finance") {
            this.he = true;
          } else if (name == "FinanceDetails") {
            this.he = true;
          } else {
            this.he = false;
          }
        });
      },
      // 初始化时自动执行
      immediate: true,
      deep: true,
    },
    userData(e) {
      this.userId = this.$store.getters.userData.id;
    },
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
  },
  filters: {},
  methods: {
    login() {
      window.location.href = process.env.VUE_APP_BASE_URL + "/cH5/login";
    },
    blankDZ,
    login_Else() {
      window.location.href = process.env.VUE_APP_BASE_URL + "/cH5/register";
    },
    loginWb() {
      window.open(process.env.VUE_APP_BASE_URL + "/cH5/home/index");
      ("_blank");
    },
    pullFlagEnter() {
      this.pullFlag = true;
    },
    pullFlagLeave() {
      setTimeout(() => {
        this.pullFlag = false;
      }, 500);
    },
    clickTab(item, index) {
      // this.$emit("trainTitle");
      // localStorage.removeItem("trainTitle");
      // if (index != "1" && index != "0" && index <= 4) {
      //   this.$emit("scollBack", true);
      // }
      // this.$emit("litsFlagGoback", false);
      // sessionStorage.setItem("changeIndex", index);
      // if (index == 1 || item.current == "0") {
      //   this.pullFlag = true;
      //   this.litsFlag = true;
      //   return;
      // } else {
      //   this.pullFlag = false;
      // }
      this.currentIndex = index;
      if (item.Url) {
        this.litsFlag = false;
        sessionStorage.setItem("scrollIntoView", JSON.stringify(item.query));
        this.$router.push({
          path: item.Url,
          query: item.query,
        });
      }
      if (item.name == "我要投标") {
        blankDZ();
      }
      //  else {
      //   this.$router.push({
      //     path: "/home/index",
      //     query: { leftQuery: "999", queryData: item.current },
      //   });
      // }
    },
    none() {},
    // hycDetail(index) {
    //   this.pullFlag = false;
    //   this.litsFlag = false;
    //   this.$emit("scollBackHyc", true);
    //   this.$router.push({
    //     path: "/hycSolve",
    //     query: { queryData: index },
    //   });
    // },
    // newDetails(index) {
    //   this.currentIndex = 1;
    //   this.pullFlag = false;
    //   this.litsFlag = false;
    //   this.$emit("litsFlagGoback", false);
    //   this.$router.push({
    //     path: "/hycSolve",
    //     query: { queryData: index },
    //   });
    // },
    topListCut(e) {
      this.currentIndex = e;
    },
    getUserInfo() {},
  },

  mounted() {
    // this.$bus.$on('trainTitle', data => {
    //   this.pullFlag = false
    // })
    // this.$bus.$on('litsFlag', data => {
    //   this.litsFlag = data
    // })
    // this.$bus.$on('policyChange', data => {
    //   this.currentIndex = '5'
    //   sessionStorage.setItem('changeIndex', '5')
    // })
    // this.$bus.$on('trainChange', data => {
    //   this.currentIndex = '6'
    //   sessionStorage.setItem('changeIndex', '6')
    // })
    // this.$bus.$on('consultChange', data => {
    //   this.currentIndex = '0'
    //   sessionStorage.setItem('changeIndex', '0')
    // })
  },
};
</script>

<style lang="less" scoped>
.h5_nav_list {
  display: none;
}
.tab_current_h5_color {
  color: #3d7fff !important;
}

@media screen and (max-width: 600px) {
  .h5_nav_list {
    width: 100%;
    height: 100%;
    display: block !important;
    background: #ffffff;
    position: fixed;
    left: 0rem;
    // top: 2.5rem;
    z-index: 1000;
    overflow: auto;
    .mc_box {
      width: 100%;
      height: 100%;
      margin-bottom: 50px;
      background: #ffffff;
      box-shadow: 0.25rem 0.5rem 1.5rem 0rem rgba(26, 69, 204, 0.22);
      border-radius: 0.3rem;
      border: 0.05rem solid rgba(83, 196, 255, 0.45);
      box-sizing: border-box;
      font-size: calc(100vw * 60 / 1920);
      overflow: auto;
      .button_box {
        width: 100%;
        height: calc(100vw * 300 / 1920);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        .button {
          width: calc(100vw * 400 / 1920);
          height: calc(100vw * 150 / 1920);
          border-radius: calc(100vw * 120 / 1920);
          border: 1px solid #3d7fff;
          font-size: calc(100vw * 80 / 1920);
          font-family: "AlibabaPuHuiTi_2_85_Bold";
          color: #3d7fff;
          margin-left: calc(100vw * 100 / 1920);
          line-height: calc(100vw * 150 / 1920);
          text-align: center;
        }
        .else_button {
          width: calc(100vw * 400 / 1920);
          height: calc(100vw * 150 / 1920);
          border-radius: calc(100vw * 120 / 1920);
          background: #3d7fff;
          font-size: calc(100vw * 80 / 1920);
          font-family: "AlibabaPuHuiTi_2_85_Bold";
          color: #ffffff;
          margin-left: calc(100vw * 100 / 1920);
          line-height: calc(100vw * 150 / 1920);
          text-align: center;
        }
      }
    }
    .list-box {
      position: relative;
      .line-box {
        height: 100%;
        width: 100%;
        position: absolute;
        .line {
          height: calc(100vw * 188 / 1920);
          border-bottom: 0.05rem solid rgba(0, 0, 0, 0.1);
        }
      }
      .toneFlag {
        padding: 0 0 0 1rem;
        width: 100%;
        font-size: 3.5vw;
        display: flex;
        box-sizing: border-box;
        font-family: "AlibabaPuHuiTi_2_65_Medium";
        color: #203152;
        .title {
          line-height: calc(100vw * 191 / 1920);
          height: calc(100vw * 191 / 1920);
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .title:hover {
          color: #3d7fff !important;
        }
        .childFlag {
          flex: 1;
          display: flex;
          text-align: left;
          overflow: hidden;
          > .title {
            margin-right: 2vw;
            width: 23vw;
            display: flex;
            align-items: center;
          }
          .childFlag_box {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            .twoFlag {
              width: 45%;
              margin-right: 1vw;
              > .title {
                width: 100%;
                margin-right: 1vw;
                font-family: "AlibabaPuHuiTi_2_65_Medium";
                display: flex;
                align-items: center;
              }

              .treFlag {
                > .title {
                  width: 100%;
                  font-family: "AlibabaPuHuiTi_2_55_Medium";
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
