import Cookies from 'js-cookie'
const TokenKey = 'c-Authorization'
const TokenH5Key = 'app-h5'

export function getToken() {
  if(window.innerWidth > 600){
    return Cookies.get(TokenKey)
  }else{
    return localStorage.getItem(TokenH5Key)
  }
}

export function setToken(token) {
  sessionStorage.setItem(TokenKey, token)
  Cookies.set(TokenKey, token)
}

export function sessionStorageGet(key) {
  return sessionStorage.getItem(key)
}

export function sessionStorageSet(key, value) {
  sessionStorage.setItem(key, value)
}

export function removeToken() {
  Cookies.set(TokenKey, '')
  sessionStorage.removeItem(TokenKey)
  localStorage.removeItem(TokenKey)
  sessionStorage.removeItem(TokenH5Key)
  localStorage.removeItem(TokenH5Key)
}