import { userInfo } from '@/api/major'
import { getToken, removeToken } from "@/untils/auth";
const user = {
  state: {
    userData: {}
  },
  mutations: {
    SET_USERDATA(state, payload) {
      localStorage.setItem('userData', JSON.stringify(payload))
      state.userData = payload
    }
  },
  actions: {
    // 初始化用户信息
    initUserData({ commit }, company) {
      // 第一清空数据
      let count = 0
      let time = setInterval(() => {
        count++
        // 清空
        if (count <= 1) {
          commit('SET_USERDATA', {})
        } 
        // 限制240秒
        if (count >= 30) {
          clearInterval(time)
        }
        // 获取token
        var token = getToken()
        if (token) {
          userInfo().then(res => {
            clearInterval(time)
            if (res.code == 200) {
              commit('SET_USERDATA', {...res.data})
            } else {
              commit('SET_USERDATA', res)
              removeToken()
            }
          })
        }
      }, 1000)
    },
    // 获取用户信息
    getUserData({ commit }, company) {
      return new Promise((resolve, reject) => {
        userInfo().then(res => {
          resolve(res)
          if (res.code == 200) {
            commit('SET_USERDATA', {...res.data})
          } else {
            commit('SET_USERDATA', res)
            removeToken()
          }
        })
      })
    }
  }
}

export default user
