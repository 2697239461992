import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'
import fengkongRoutes from './modules/fengkong'
import bannerServicesRoutes from './modules/bannerServices'
import contractRoutes from './modules/contract'
import wenjianbiduiRoutes from './modules/wenjianbidui'
const Finance = () => import('../views/finance/index.vue')
const FinanceDetails = () => import('../views/finance/details.vue')
const Loans = () => import('../views/loans/index.vue');
const Lingtan = () => import('../views/lingtan/index.vue');
const Policy = () => import('../views/policy/index.vue');
const Regardme = () => import('../views/regardme/index.vue');
const HycSolve = () => import('../views/hycsolve/index.vue');
const PolicyDetails = () => import('../views/policy/details.vue');
const InfromDetails = () => import('../views/inform/indexDetails.vue');
const Zcnotice = () => import('../views/zcnotice/index.vue');
const Zcnotice_detale = () => import('../views/zcnotice/detale.vue');
const Zcnotice_detale_xq = () => import('../views/zcnotice/detale_xq.vue');
let Potential = () => import('../views/potential/index.vue')
const Supply = () => import('../views/supply/index.vue');
const Consulting = () => import('../views/consulting/index.vue');
const assetsDisposal = () => import('../views/idle/index.vue');
const InquiryIndex = () => import('../views/inquiry/index.vue');
const ControlIndex = () => import('../views/control/index.vue');
const PreferredIndex = () => import('../views/preferred/index.vue');
let Enterprise = () => import('../views/enterprise/introduce.vue');
const YiJianIndex = () => import('../views/yiJian/index.vue');
let home = () => import('../views/home/index.vue')
const Infrom = () => import('../views/inform/index.vue');
const ConsultDetails = () => import('../views/consult/details.vue');
const Consult = () => import('../views/consult/index.vue');
const Train = () => import('../views/train/index.vue');
const Detale = () => import('../views/idle/detale.vue');
const Detale2 = () => import('../views/idle/detale2.vue');
const DetailPage = () => import('../views/idle/DetailPage.vue');
const DetailPage2 = () => import('../views/idle/DetailPage2.vue');
const TBAgreementList = () => import('../views/agreement/TBAgreementList.vue');
const AgreementList = () => import('../views/agreement/AgreementList.vue');
const DataAgreement = () => import('../views/agreement/data-agreement.vue');
const TBDataAgreement = () => import('../views/agreement/TB-data-agreement.vue');

if (window.innerWidth <= 600) {
  Potential = () => import('../views/potential/index_h5.vue')
  Enterprise = () => import('../views/enterprise/introduce_h5.vue');
  home = () => import('../views/home/index_h5.vue')
}
Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    path: '/',
    component: Layout,
    redirect: '/home/index',
    meta: {
      title: '首页'
    },
    showedChild: [],
    alias: ['/content', '/index'],
    children: [
      {
        path: '/home/index',
        name: 'homeIndex',
        component: home,
        meta: {
          title: ''
        }
      },
      {
        path: "/control/index",
        name: "controlIndex",
        component: ControlIndex //化云交付,
      },
      {
        path: "/preferred/index",
        name: "preferredIndex",
        component: PreferredIndex //化云甄选,
      },
      {
        path: "/inquiry/index",
        name: "inquiryIndex",
        component: InquiryIndex //化云寻价,
      },
      {
        path: "/yiJian/index",
        name: "yiJianIndex",
        component: YiJianIndex //化易建,
      },
      {
        path: '/home/index1',
        name: 'homeIndex1',
        component: home,
      },
      {
        path: "/financeDetails",
        name: "FinanceDetails",
        component: FinanceDetails //金融服务详情页,
      },
      {
        path: "/finance",
        name: "Finance",
        component: Finance //金融服务,
      },
      {
        path: "/loans",
        name: "Loans",
        component: Loans,
      },
      {
        path: "/lingtan",
        name: "Lingtan",
        component: Lingtan,//化云零碳
      },
      {
        path: '/enterprise',
        name: "enterprise",
        component: Enterprise, //工程管理能力评估
      },
      {
        path: "/policy",
        name: "Policy",
        component: Policy,//政策法规
      },
      {
        path: "/policyDetails",
        name: "PolicyDetails",
        component: PolicyDetails,//政策法规详情
      },
      {
        path: "/regardme",
        name: "Regardme",
        component: Regardme,//关于我们
      },
      {
        path: "/infrom",
        name: "Infrom",
        component: Infrom, // 通知
      },
      {
        path: "/infromDetails",
        name: "infromDetails",
        component: InfromDetails,//公告1
      },
      {
        path: "/hycSolve",
        name: "HycSolve",
        component: HycSolve,//化易采
      },
      {
        path: "/zcnotice",
        name: "Zcnotice",
        component: Zcnotice_detale,//化云招采公告
      },
      {
        path: "/zcnotice/detale",
        name: "Zcnotice_detale",
        component: Zcnotice,//化云招采公告详情
      },
      {
        path: "/zcnotice/detale/xq",
        name: "Zcnotice_detale_xq",
        component: Zcnotice_detale_xq,//公告详情
      },
      {
        path: "/potential",
        name: "Potential",
        component: Potential,//化云潜客
      },
      {
        path: "/supply",
        name: "Supply",
        component: Supply,//供应链服务
      },
      {
        path: "/consulting",
        name: "Consulting",
        component: Consulting,//产业咨询
      },
      {
        path: "/train",
        name: "Train",
        component: Train,
      },
      {
        path: "/assetsDisposal",
        name: "assetsDisposal",
        component: assetsDisposal,//化云闲余
      },
      {
        path: "/assetsDisposal/detale",
        name: "Detale",
        component: Detale,//化云闲余
      },
      {
        path: "/assetsDisposal/detale2",
        name: "Detale2",
        component: Detale2,//化云闲余
      },
      {
        path: "/assetsDisposal/DetailPage",
        name: "DetailPage",
        component: DetailPage,//化云闲余
      },
      {
        path: "/assetsDisposal/DetailPage2",
        name: "DetailPage2",
        component: DetailPage2,//化云闲余
      },

      {
        path: '/consultDetails',
        name: 'ConsultDetails',
        component: ConsultDetails,
      },
      {
        path: '/consult',
        name: 'Consult',
        component: Consult,
      },
      /**
   * 投标协议列表
   */
      {
        path: '/agreement/tb-agreement-list',
        name: 'TBAgreementList',
        component: TBAgreementList
      },
      {
        path: '/agreement/tb-data-agreement',
        name: 'TBDataAgreement',
        component: () => TBDataAgreement
      },
      /**
  * 协议列表
  */
      {
        path: '/agreement/agreement-list',
        name: 'AgreementList',
        component: AgreementList
      },
      {
        path: '/agreement/data-agreement',
        name: 'DataAgreement',
        component: () => DataAgreement
      },
    ]
  },
  ...fengkongRoutes,
  ...bannerServicesRoutes,
  ...contractRoutes,
  ...wenjianbiduiRoutes
]


const router = new VueRouter({
  routes,
  // mode: 'history',
  scrollBehavior () {
    //无论切换到哪个路由，都会将滚动位置设为 { x: 0, y: 0 }，即横坐标和纵坐标都归零。
    return { x: 0, y: 0 };
  }
});

// 解决 Vue Router 在导航到相同的路径时产生的错误问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router
