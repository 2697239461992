import Layout from '@/components/Layout'
let wenjianbidui = () => import('@/views/wenjianbidui/index.vue')

if (window.innerWidth <= 600) {
  wenjianbidui = () => import('@/views/wenjianbidui/index_h5.vue')
}

const routes = [
  {
    name: 'wenjianbidui',
    component: Layout,
    path: '/wenjianbidui',
    redirect: '/wenjianbidui/index',
    meta: {
      title: '文件对比'
    },
    showedChild: [],
    children: [
      {
        path: '/wenjianbidui/index',
        name: 'wenjianbiduiIndex',
        component: wenjianbidui,
        meta: {
          title: ''
        }
      }
    ]
  }
]

export default routes