<template>
  <div class="header">
    <div class="header_body">
      <div
        class="icon_left"
        v-if="
          !(
            $route.query.noNav &&
            $route.path.includes('/assetsDisposal') &&
            !$cookie.get('c-Authorization')
          )
        "
      >
        <div class="img_left">
          <img src="../../../assets/img/log.png" alt="" />
        </div>
        <div class="title_left">
          <div>中化商务数字化服务平台</div>
        </div>
      </div>
      <div
        class="icon_left"
        v-if="
          $route.query.noNav &&
          $route.path.includes('/assetsDisposal') &&
          !$cookie.get('c-Authorization')
        "
      >
        <div style="margin-left: 10px; width: 40%">
          <img style="width: 100%" src="../../../assets/idle/xbbj.jpg" alt="" />
        </div>
      </div>
      <div class="icon_right">
        <template
          v-if="
            !(
              $route.query.noNav &&
              $route.path.includes('/assetsDisposal') &&
              !$cookie.get('c-Authorization')
            )
          "
        >
          <div class="search_right">
            <ul class="list">
              <li
                v-for="(item, index) in topList"
                :key="index"
                @click="typeCub(item)"
                :class="item.index == spealIndex ? 'active_top' : ''"
              >
                {{ item.title }}
              </li>
            </ul>
            <div class="sx"></div>
            <div class="sex_div">
              <el-input
                placeholder-class="placeholderClass"
                v-model="input1"
                class="search_input"
                suffix-icon="el-icon-search"
                @keyup.enter.native="toSarch"
                @input="changeIuput"
                @blur="blurEvent"
                placeholder="请输入关键词"
                :style="{
                  'text-align': input1.length <= 0 ? 'right' : 'left',
                }"
              >
              </el-input>
              <ul
                class="infinite-list"
                style="overflow: auto"
                v-if="scrollFlag"
              >
                <li
                  v-for="item in tableDate3"
                  :key="item.subjectId"
                  @click="detailsALL3(item)"
                >
                  <div>{{ item.subjectName }}</div>
                </li>
                <li
                  v-for="item in tableDate2"
                  :key="item.id"
                  @click="detailsALL2(item)"
                >
                  <div>{{ item.title }}</div>
                </li>
                <li
                  v-for="item in tableDate"
                  :key="item.id"
                  @click="detailsALL(item)"
                >
                  <div>{{ item.title }}</div>
                </li>
                <li
                  v-if="
                    tableDate.length <= 0 &&
                    tableDate2.length <= 0 &&
                    tableDate3.length <= 0
                  "
                  style="text-align: center"
                >
                  暂无数据
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template
          v-if="
            $route.query.noNav &&
            $route.path.includes('/assetsDisposal') &&
            !$cookie.get('c-Authorization')
          "
        >
          <div class="search_right" style="margin-right: 8rem">
            <div class="sex_div">
              <el-input
                placeholder-class="placeholderClass"
                v-model="input1"
                class="search_input"
                suffix-icon="el-icon-search"
                @keyup.enter.native="toSarch"
                @input="changeIuput"
                @blur="blurEvent"
                placeholder="请输入关键词"
                :style="{
                  'text-align': input1.length <= 0 ? 'right' : 'left',
                }"
              >
              </el-input>
              <ul
                class="infinite-list"
                style="overflow: auto"
                v-if="scrollFlag"
              >
                <li
                  v-for="item in tableDate3"
                  :key="item.subjectId"
                  @click="detailsALL3(item)"
                >
                  <div>{{ item.subjectName }}</div>
                </li>
                <li
                  v-for="item in tableDate2"
                  :key="item.id"
                  @click="detailsALL2(item)"
                >
                  <div>{{ item.title }}</div>
                </li>
                <li
                  v-for="item in tableDate"
                  :key="item.id"
                  @click="detailsALL(item)"
                >
                  <div>{{ item.title }}</div>
                </li>
                <li
                  v-if="
                    tableDate.length <= 0 &&
                    tableDate2.length <= 0 &&
                    tableDate3.length <= 0
                  "
                  style="text-align: center"
                >
                  暂无数据
                </li>
              </ul>
            </div>
          </div>
        </template>
        <div class="login_right" v-if="!userData.username">
          <div @click="login">登&nbsp;&nbsp;录</div>
          <el-button
            style="background-color: #3d7fff; border-color: #3d7fff"
            type="primary"
            round
            @click="loginOut"
            >立即注册</el-button
          >
        </div>
        <div class="speal" v-if="userData.username?.length >= 1 ? true : false">
          <div class="info">
            <div class="speal_icon" style="margin-right: 0.3rem"></div>

            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="userData.username"
            >
              <div slot="reference" class="abc">
                欢迎{{ userData.username }}
              </div>
            </el-popover>
          </div>
          <div class="loginWb" v-if="!isRisks" @click="loginWb">进入工作台</div>
        </div>
      </div>
    </div>
    <div
      class="header_body_h5"
      style="position: fixed; z-index: 9999"
      v-if="!sexFlag"
    >
      <div class="header_body_h5_body">
        <div
          class="left_login"
          v-if="
            !(
              $route.query.noNav &&
              $route.path.includes('/assetsDisposal') &&
              !$cookie.get('c-Authorization')
            )
          "
        >
          <div class="gs_icon" @click="goIndex"></div>
          <div class="h5_hearder_title"></div>
        </div>
        <div
          class="icon_left"
          v-if="
            $route.query.noNav &&
            $route.path.includes('/assetsDisposal') &&
            !$cookie.get('c-Authorization')
          "
        >
          <div style="margin-left: 10px; width: 40%">
            <img
              style="width: 100%"
              src="../../../assets/idle/xbbj.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="right_icon">
          <div class="left_icon_sex" @click="h5_sex"></div>
          <div class="right_icon_menu" @click="h5_tabs"></div>
        </div>
      </div>
    </div>
    <div class="bom_white_box" v-if="!sexFlag"></div>

    <div class="h5_sex_box" v-if="sexFlag">
      <div class="top_box">
        <div class="icon_box">
          <i class="el-icon-arrow-left" @click="sexFlag = false"></i>
        </div>
        <div class="search_box">
          <el-input
            v-model="input1"
            placeholder="请输入内容"
            @input="changeIuput"
            @blur="blurEvent"
          ></el-input>
          <div class="sex_button" @click="toSarch">搜索</div>
          <ul class="infinite-list" style="overflow: auto" v-if="scrollFlag">
            <li
              v-for="item in tableDate3"
              :key="item.subjectId"
              @click="detailsALL3(item)"
            >
              <div style="font-size: 0.75rem; margin-right: 0.5rem">
                <i class="el-icon-search"></i>
              </div>
              <div class="tableBBB">{{ item.subjectName }}</div>
            </li>
            <li
              v-for="item in tableDate2"
              :key="item.id"
              @click="detailsALL2(item)"
            >
              <div style="font-size: 0.75rem; margin-right: 0.5rem">
                <i class="el-icon-search"></i>
              </div>
              <div class="tableBBB">{{ item.title }}</div>
            </li>

            <li
              v-for="item in tableDate"
              :key="item.id"
              @click="detailsALL(item)"
            >
              <div style="font-size: 0.75rem; margin-right: 0.5rem">
                <i class="el-icon-search"></i>
              </div>
              <div class="tableBBB">{{ item.title }}</div>
            </li>
            <li
              v-if="
                tableDate.length <= 0 &&
                tableDate2.length <= 0 &&
                tableDate3.length <= 0
              "
            >
              <div style="width: 100%; text-align: center">暂无数据</div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="bom_list_box"
        v-if="
          !(
            $route.query.noNav &&
            $route.path.includes('/assetsDisposal') &&
            !$cookie.get('c-Authorization')
          )
        "
      >
        <div
          v-for="(item, index) in topList"
          :key="index"
          :class="item.index == spealIndex ? 'active_top' : ''"
          @click="typeCub(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryAllInfoList, hy_bulletin_find } from "../../../api/home";
import { idleAssets_find } from "@/api/idle";
export default {
  data() {
    return {
      isRisks: false,
      input1: "",
      scrollFlag: false,
      tableDate: [],
      tableDate2: [],
      tableDate3: [],
      topList: [
        { title: "全部", index: "0" },
        { title: "招采公告", index: "4" },
        { title: "化云闲余", index: "5" },
        { title: "政策法规", index: "1" },
        { title: "培训课程", index: "2" },
        { title: "资讯", index: "3" },
      ],
      spealIndex: "0",
      litsFlagGoback: false,
      username: "",
      time_Out: null,
      sexFlag: false,
      hover_trueFlag: true,
    };
  },
  watch: {
    userData() {},
    $route() {
      this.litsFlagGoback = false;
    },
  },
  computed: {
    userData() {
      this.isRisks = JSON.parse(localStorage.getItem("isRisks"));
      return this.$store.getters.userData;
    },
  },
  created() {
    this.$store.dispatch("initUserData");
  },
  mounted() {},
  methods: {
    login() {
      if (window.innerWidth > 600) {
        window.location.href = process.env.VUE_APP_BASE_URL + "/cWeb/login";
      } else {
        window.location.href = process.env.VUE_APP_BASE_URL + "/cH5/login";
      }
    },
    h5_sex() {
      this.sexFlag = true;
      this.litsFlagGoback = false;
      this.$emit("litsFlag", this.litsFlagGoback);
    },
    loginOut() {
      if (window.innerWidth > 600) {
        window.location.href = process.env.VUE_APP_BASE_URL + "/cWeb/register";
      } else {
        window.location.href = process.env.VUE_APP_BASE_URL + "/cH5/register";
      }
    },
    loginWb() {
      if (window.innerWidth > 600) {
        window.open(process.env.VUE_APP_BASE_URL + "/cWeb/home/index");
        ("_blank");
      } else {
        window.open(process.env.VUE_APP_BASE_URL + "/cH5/home/index");
        ("_blank");
      }
    },
    goIndex() {
      this.$router.push({
        path: "/content",
      });
    },
    // getUserInfo() {
    //   this.time_Out = setInterval(() => {
    //     var token = getToken();
    //     // var token =
    //     //   this.$cookie.get("c-Authorization") ||
    //     //   localStorage.getItem("app-h5") ||
    //     //   sessionStorage.getItem("c-Authorization");

    //     if (!token) {
    //       return;
    //     }

    //     // this.$resetSetItem(0, "c-Authorization", token);
    //     // this.$resetSetItem(1, "app-h5", token);
    //     userInfo(token).then((res) => {
    //         if (res.data.code != 200) {
    //           clearInterval(this.time_Out);
    //           return;
    //         }
    //         let username = res.data?.data?.username;
    //         if(!username){
    //           removeToken()
    //           this.getUserInfo()
    //         }
    //         this.username = username
    //         sessionStorage.setItem("username", this.username);
    //         this.userId = res.data.data.id;
    //         sessionStorage.setItem("userId", this.userId);
    //         let userOBj = {
    //           companyId: res.data?.data?.companyId,
    //           companyName: res.data?.data?.companyName,
    //           busLicenseCode: res.data?.data?.busLicenseCode,
    //         };
    //         sessionStorage.setItem("userobj", JSON.stringify(userOBj));

    //         if (this.username?.length >= 1) {
    //           clearInterval(this.time_Out);
    //         }
    //       }) //请求成功
    //       .catch((res) => {
    //         clearInterval(this.time_Out);
    //         return res;
    //       }); //请求失败
    //   }, 2000);
    // },
    toSarch() {
      this.changeIuput();
    },
    typeCub(item) {
      this.spealIndex = item.index;
    },
    blurEvent() {
      setTimeout(() => {
        this.scrollFlag = false;
      }, 300); //设置300ms后让下拉框消失
    },
    detailsALL3(item) {
      this.sexFlag = false;
      this.input1 = "";
      this.$router.push({
        path: "/assetsDisposal/DetailPage",
        query: {
          id: item.subjectId,
          transactionMode: item.transactionMode,
        },
      });
    },
    async changeIuput() {
      if (this.input1 === "") {
        this.tableDate = [];
        this.scrollFlag = false;
        return;
      }

      let data11 = await idleAssets_find({
        keyword: this.input1,
        size: 10,
        current: 1,
      });

      if (data11.code === "200") {
        if (this.spealIndex == "0" || this.spealIndex == "5") {
          this.tableDate3 = data11.data.records;
          this.scrollFlag = true;
        }
      } else {
        this.tableDate3 = [];
        this.$message.error(data11.msg || "查询错误");
      }
      if (
        (this.$route.query.noNav && this.$route.path === "/assetsDisposal") ||
        this.spealIndex == "5"
      ) {
        this.tableDate = [];
        this.tableDate2 = [];
        return;
      }
      let data = await queryAllInfoList(this.input1);
      if (data.code === "200") {
        this.tableDate = data.data;
        //1政策法规   2课程培训 3平台咨询
        if (this.spealIndex == "1") {
          this.tableDate = this.tableDate.filter((item) => {
            return item.type == "1";
          });
        } else if (this.spealIndex == "2") {
          this.tableDate = this.tableDate.filter((item) => {
            return item.type == "2";
          });
        } else if (this.spealIndex == "3") {
          this.tableDate = this.tableDate.filter((item) => {
            return item.type == "3";
          });
        } else if (this.spealIndex == "4") {
          this.tableDate = [];
        }
        this.scrollFlag = true;
      } else {
        this.$message.error(data.msg || "查询错误");
      }

      let data2 = await hy_bulletin_find({
        keyword: this.input1,
        size: 10,
        current: 1,
      });
      if (data2.code === "200") {
        if (
          this.spealIndex !== "1" &&
          this.spealIndex !== "2" &&
          this.spealIndex !== "3"
        ) {
          this.tableDate2 = data2.data.records;
          this.scrollFlag = true;
        } else {
          this.tableDate2 = [];
        }
      } else {
        this.tableDate2 = [];
        this.$message.error(data2.msg || "查询错误");
      }
    },
    detailsALL2(item) {
      this.sexFlag = false;
      this.input1 = "";
      this.$router.push({
        path: "/zcnotice/detale/xq",
        query: { id: item.id },
      });
    },
    detailsALL(item) {
      this.sexFlag = false;
      this.input1 = "";
      // 1政策法规   2课程培训 3平台咨询
      if (item.type == "1") {
        this.scrollFlag = false;
        // this.$bus.$emit("policyChange", true);
        this.$router.push({
          path: "/infromDetails",
          query: { policyID: item.id, title: "0" },
        });
      } else if (item.type == "2") {
        // this.$bus.$emit("trainChange", item.title);
        this.scrollFlag = false;
        localStorage.setItem("trainTitle", item.title);
        this.$router.push({
          path: "/train",
          query: { title: item.title },
        });
      } else if (item.type == "3") {
        this.scrollFlag = false;
        this.$router.push({
          path: "/infromDetails",
          query: { consultID: item.id, title: "1" },
        });
      } else {
        return;
      }
    },
    h5_tabs() {
      this.litsFlagGoback = !this.litsFlagGoback;
      this.$emit("litsFlag", this.litsFlagGoback);
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 0rem;
  height: 1rem;
}
.active_top {
  color: #3d7fff !important;
}
.header {
  width: 100%;
  background: #ffffff;
  .h5_sex_box {
    display: none;
  }
  .header_body {
    width: 100%;
    height: 5.5rem;
    background: #ffffff;
    padding: 0rem 1.25rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    .icon_left {
      width: 18.2rem;
      min-width: 165px;
      height: 3.2rem;
      margin-right: calc(100vw * 120 / 1920);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img_left {
        width: 4.5rem;
        height: 3.4rem;
        min-width: 34px;
        min-height: 25px;
        img {
          width: 100%;
        }
      }
      .title_left {
        height: 100%;
        font-size: 1.2rem;
        font-family: AlibabaPuHuiTi_2_75_SemiBold;
        color: #203152;
        div {
          height: 100%;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .icon_right {
      flex: 1;
      height: 2.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search_right {
        width: 100%;
        height: 2.5rem;
        min-height: 25px;
        background: #edf2f5;
        border-radius: 2rem;
        border: 1px solid #dae0e1;
        display: flex;
        align-items: center;
        padding: 0rem 1.35rem;
        box-sizing: border-box;
        .list {
          // width: 20rem;
          min-width: 270px;
          li {
            font-size: 0.75rem;
            font-family: "AlibabaPuHuiTi_2_65_Medium";
            color: #203152;
            margin-right: 0.5rem;
            cursor: pointer;
            float: left;
          }
        }
        .sx {
          width: 0.05rem;
          height: 0.75rem;
          background: #203152;
          margin-right: 1rem;
        }
        .sex_div {
          flex: 1;
          // width: 33rem !important;
          background: #edf2f5 !important;
          position: relative;
          /deep/ .search_input {
            width: 100% !important;
            background: #edf2f5 !important;
            /deep/ .el-input__suffix .el-input__suffix-inner .el-input__icon {
              font-size: 1.4rem !important;
            }
          }
          /deep/ .el-input__inner {
            border: none !important;
            background: #edf2f5 !important;
            line-height: calc(100vw * 40 / 1920);
            height: calc(100vw * 40 / 1920);
            font-size: 1vw !important;
          }
          /deep/ .el-icon-search {
            line-height: calc(100vw * 40 / 1920);
          }
          .placeholder {
            position: absolute;
            left: 0rem;
            top: 0rem;
          }
        }

        .infinite-list {
          width: 100%;
          height: 16rem;
          background: #ffffff;
          position: absolute;
          left: 0rem;
          top: 2.5rem;
          z-index: 1100;
          padding: 1rem 1rem;
          box-sizing: border-box;
          li {
            width: 100%;
            font-size: calc(100vw * 15 / 1920);
            font-family: "AlibabaPuHuiTi_2_45_Light";
            color: #203152;
            line-height: calc(100vw * 35 / 1920);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          li:hover {
            background: #f5f5f6;
            color: #3d7fff;
          }
        }
        .search_list {
          width: 15rem;
          font-size: calc(100vw * 15 / 1920);
          font-family: "AlibabaPuHuiTi_2_65_Medium";
          color: #203152;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0rem 0.4rem;
          box-sizing: border-box;
          li {
            cursor: pointer;
            float: left;
          }
          // li:hover {
          //   background: #878787;
          // }
          /deep/ .el-input__inner {
            font-size: 0.75rem !important;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .login_right {
        margin-left: calc(100vw * 60 / 1920);
        width: calc(100vw * 250 / 1920);
        min-width: calc(100vw * 250 / 1920);
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: right;
        font-size: calc(100vw * 15 / 1920);
        font-family: "AlibabaPuHuiTi_2_65_Medium";
        color: #203152;
        // margin-right: 2rem;
        div {
          display: flex;
          align-items: center;
          width: calc(100vw * 40 / 1920);
          height: calc(100vw * 21 / 1920);
          font-size: calc(100vw * 16 / 1920);
          margin-right: calc(100vw * 40 / 1920);
          font-family: "AlibabaPuHuiTi_2_65_Medium";
          color: #203152;
          cursor: pointer;
        }
        /deep/ .el-button {
          width: calc(100vw * 120 / 1920) !important;
          height: calc(100vw * 40 / 1920) !important;
          min-width: 60px;
          min-height: 25px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          font-size: 0.8vw;
          padding: 0 !important;
          // span {
          //   width: calc(100vw * 89 / 1920) !important;
          //   text-align: center;
          //   font-size: calc(100vw * 16 / 1920) !important;
          //   font-family: "AlibabaPuHuiTi_2_65_Medium";
          //   color: #ffffff;
          //   line-height: calc(100vw * 22 / 1920) !important;
          // }
        }
      }
      .speal {
        position: relative;
        display: flex;
        align-content: center;
        margin-right: 0rem !important;
        overflow: visible;
        margin-left: calc(100vw * 40 / 1920);
        .info {
          display: flex;
          align-items: center;
          .speal_icon {
            width: 1.3rem;
            height: 1.5rem;
            background: url("../../../assets/img/pc_img/hearderIcon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .abc {
            width: calc(100vw * 160 / 1920);
            margin-right: 20px;
            font-size: 0.9rem;
            font-family: "AlibabaPuHuiTi_2_65_Medium";
            color: #203152;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .loginWb {
          cursor: pointer;
          display: block;
          width: 6.75rem;
          height: 2rem;
          cursor: pointer;
          background: #3d7fff;
          border-radius: 1.2rem;
          font-size: 0.8rem;
          font-family: "AlibabaPuHuiTi_2_65_Medium";
          color: #ffffff;
          line-height: 2rem;
          text-align: center;
        }
      }
    }
  }
  .header_body_h5 {
    display: none;
  }
  .bom_white_box {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .header_body {
    display: none !important;
  }
  .active_top {
    color: #3d7fff !important;
  }
  .header_body_h5 {
    width: 100vw;
    height: 51px;
    background: #ffffff;
    box-sizing: border-box;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    box-sizing: border-box;
    .header_body_h5_body {
      width: 100%;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 11.73px;
      box-sizing: border-box;
      .left_login {
        display: flex;
        align-items: center;
        flex: 1;
        .gs_icon {
          width: 40.7px;
          height: 31px;
          background-image: url("../../../assets/img/h5_img/logo.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          float: left;
        }
        .h5_hearder_title {
          margin-left: 10px;
          height: 17.25px;
          width: 138px;
          background-image: url("../../../assets/img/h5_img/top_zi.png");
          background-size: 100% 100%;
        }
      }
      .right_icon {
        width: 70px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left_icon_sex {
          width: 20px;
          height: 20px;
          background-image: url("../../../assets/img/h5_img/search.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .right_icon_menu {
          width: 23px;
          height: 20px;
          background-image: url("../../../assets/img/h5_img/menu.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .bom_white_box {
    width: 100%;
    height: 51px;
    background: #ffffff;
    display: block !important;
  }
  .h5_sex_box {
    width: 100vw;
    height: 100%;
    position: fixed;
    background: #ffffff;
    z-index: 100;
    display: block !important;
    .top_box {
      width: 100%;
      height: 2.35 * 3vw;
      padding: 0 * 3vw 0.8 * 3vw;
      box-sizing: border-box;
      margin-top: 0.5 * 3vw;
      display: flex;
      justify-content: space-between;
      .icon_box {
        width: 1.4 * 3vw;
        height: 2.35 * 3vw;
        font-size: 1.4 * 3vw;
        display: flex;
        align-items: center;
        margin-right: 0.5 * 3vw;
      }
      .search_box {
        width: 16.6 * 3vw;
        height: 2.35 * 3vw;
        border-radius: 1.2 * 3vw;
        border: 0.05 * 3vw solid #3d7fff;
        position: relative;
        flex: 1;

        .el-input {
          width: 100%;
          height: 100%;
          /deep/ .el-input__inner {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 1.2 * 3vw;
            line-height: inherit;
          }
        }
        .sex_button {
          width: calc(100vw * 200 / 1920);
          min-width: 50px;
          height: 25px;
          background: #3d7fff;
          border-radius: calc(100vw * 200 / 1920);
          position: absolute;
          right: 0.2vw;
          top: 50%;
          margin-top: -12.5px;
          font-size: 16px;
          font-family: "AlibabaPuHuiTi_2_65_Medium";
          color: #ffffff;
          line-height: 25px;
          text-align: center;
        }
        .infinite-list {
          width: 107%;
          height: 90vh;
          background: #ffffff;
          position: absolute;
          left: -2 * 3vw;
          top: 2.5 * 3vw;
          li {
            width: 100%;
            font-size: 16px;
            font-family: "AlibabaPuHuiTi_2_45_Light";
            color: #203152;
            line-height: 40px;
            border-bottom: 0.05 * 3vw solid rgba(0, 0, 0, 0.1) !important;
            display: flex;
            align-items: center;
            cursor: pointer;
            .tableBBB {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
              line-height: 25px;
            }
          }
          li:hover {
            background: #f5f5f6;
            color: #3d7fff;
          }
        }
      }
    }
    .bom_list_box {
      width: 100%;
      height: 1.4 * 3vw;
      padding: 0 * 3vw 0 * 3vw 0 * 3vw 3 * 3vw;
      box-sizing: border-box;
      margin-top: 1 * 3vw;
      div {
        height: 100%;
        float: left;
        font-size: 3.5vw;
        font-family: "AlibabaPuHuiTi_2_65_Medium";
        color: #203152;
        line-height: 1.4 * 3vw;
        margin-right: 1 * 3vw;
      }
    }
  }
}
</style>
