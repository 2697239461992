import Vue from 'vue'
import App from './App.vue'
import '@/permission' // permission control
import router from './router'
import store from './store'
// rem单位
import "./untils/detectZoom.js";
// 字体
import "./styles/font.css";
// 默认样式
import "./styles/reset.css";


import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import cookie from "js-cookie";
Vue.prototype.$cookie = cookie;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import axios from "axios";
Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
