import router from './router'


router.beforeEach((to, from, next) => {
  if (to.path === '/hghyy') {
    window.location.href = 'https://chemcloud.cn/home'
  }

  // 检查来源路由的查询参数中是否包含 noNav
  if (from.query.noNav === '1') {
    // 如果目标路由的查询参数中不包含 noNav，则添加它
    if (!to.query.noNav) {
      // 使用对象展开运算符来合并查询参数
      const newQuery = { ...to.query, noNav: '1' };
      // 修改路由以包含新的查询参数
      next({ ...to, query: newQuery });
    } else {
      // 如果目标路由已经包含 noNav，则直接放行
      next();
    }
  } else {
    // 如果来源路由不包含 noNav，则直接放行
    next();
  }
})

