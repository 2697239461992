
import Layout from '@/components/Layout'
let contract = () => import('@/views/contract/index.vue')
if (window.innerWidth <= 600) {
  contract = () => import('@/views/contract/index_h5.vue')
}
const routes = [
  {
    name: 'contract',
    component: Layout,
    path: '/contract',
    redirect: '/contract/index',
    meta: {
      title: '合同'
    },
    showedChild: [],
    children: [
      {
        path: '/contract/index',
        name: 'contractIndex',
        component: contract,
        meta: {
          title: ''
        }
      }
    ]
  }
]

export default routes