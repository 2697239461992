<template>
  <div id="app">
    <!-- 展示对应的路由组件 -->
    <router-view />
  </div>
</template>

<script>
export default {
  // 文件名称
  name: "app",
  // data: 存放数据地方，必须是return一个object的函数
  data() {
    return {};
  },

  // 存放函数的地方
  methods: {},

  //components:存放组件地方
  components: {},

  // computed：计算属性
  computed: {},

  //监听
  watch: {},

  //过滤器
  filters: {},

  mounted() {
    sessionStorage.removeItem("oldUrl");

    window.open = (url) => {
      if (url.includes("login")) {
        // 在这里处理window.open()方法
        sessionStorage.setItem("oldUrl", window.location.href);
      }
      if (url.includes("register")) {
        // 在这里处理window.open()方法
        sessionStorage.setItem("oldUrl", window.location.href);
      }
      window.location.href = url;
    };
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  font-size: 14px;
}
</style>
<style>
.toast-box {
  width: auto !important;
}
</style>
