var render = function render(){var _vm=this,_c=_vm._self._c;return (
    !(
      _vm.$route.query.noNav &&
      _vm.$route.path.includes('/assetsDisposal') &&
      !_vm.$cookie.get('c-Authorization')
    )
  )?_c('div',{staticClass:"Nav"},[_vm._l((_vm.navList),function(item){return _c('div',{key:item.id},[(item.children)?_c('el-popover',{attrs:{"placement":"bottom","trigger":"hover"},model:{value:(item.visible),callback:function ($$v) {_vm.$set(item, "visible", $$v)},expression:"item.visible"}},[_c('div',{staticClass:"pull_down"},_vm._l((item.children),function(child){return _c('div',{key:child.title,staticClass:"pull_left"},[_c('div',{staticClass:"pull_title"},[_vm._v(_vm._s(child.title))]),_c('ul',_vm._l((child.list),function(data){return _c('li',{key:data.name,on:{"click":function($event){return _vm.navChildChange(item, data)}}},[_vm._v(" "+_vm._s(data.name)+" ")])}),0)])}),0),_c('div',{class:['title', _vm.id == item.id ? 'on' : ''],attrs:{"slot":"reference"},on:{"click":function($event){return _vm.navChange(item)}},slot:"reference"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_c('div',{class:['title', _vm.$route.path?.indexOf(item.Url) !== -1 ? 'on' : ''],on:{"click":function($event){return _vm.navChange(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }