
import Layout from '@/components/Layout'
//标讯
let bannerServices = () => import('@/views/Added-services/services/banner-services.vue');

if (window.innerWidth <= 600) {
  bannerServices = () => import('@/views/Added-services/services/banner-detail_h5.vue');
}
const routes = [
  {
    name: 'bannerServices',
    component: Layout,
    path: '/bannerServices',
    redirect: '/bannerServices/index',
    meta: {
      title: '标讯'
    },
    showedChild: [],
    children: [
      {
        path: '/bannerServices/index',
        name: 'bannerServices',
        component: bannerServices,
        meta: {
          title: ''
        }
      },
    ]
  }
]

export default routes